import { defineStore } from "pinia";
import type { ProductEntity } from "~/types/entities/product.type";

interface ProductSliceState {
  showProductOverViewModal: boolean;
  selectedProduct: ProductEntity | null;
  showDealCustomizationModal: boolean;
  selectedDealProduct: ProductEntity | null;
}

export const useProductsStore = defineStore("productSlice", {
  state: (): ProductSliceState => {
    return {
      showProductOverViewModal: false,
      selectedProduct: null,
      showDealCustomizationModal: false,
      selectedDealProduct: null,
    };
  },
  actions: {
    toggleProductOverviewModal(product: ProductEntity) {
      this.showProductOverViewModal = !this.showProductOverViewModal;
      if (this.showProductOverViewModal) {
        this.selectedProduct = product;
      } else {
        this.selectedProduct = null;
      }
    },
    toggleDealModal(product: ProductEntity) {
      this.showDealCustomizationModal = !this.showDealCustomizationModal;
      if (this.showDealCustomizationModal) {
        this.selectedDealProduct = product;
      } else {
        this.selectedDealProduct = null;
      }
    },
  },
});
